<template>
  <div class="partition-form">
    <van-form ref="appForm">
      <van-field label="发票种类：" placeholder="请输入发票种类" :value="utils.statusFormat(appForm.sealType,'sealType')"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('sealList')"
      />
      <van-field label="关联订单名称：" placeholder="请输入关联订单名称" :value="utils.statusFormat(appForm.orderId,'sealType')"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('sealList')"
      />
      <van-cell title="订单编号：" :value="utils.isEffectiveCommon(appForm.orderNo)" />
      <van-field v-model="appForm.invoiceSendTypeLabel" label="开票方类型：" placeholder="请输入开票方类型" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceSendType')"
      />
      <van-field v-model="appForm.invoiceReceiveTypeLabel" label="收票方类型：" placeholder="请输入收票方类型" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceReceiveType')"
      />
      <van-field v-if="appForm.invoiceSendType === 6" v-model="appForm.invoiceSendName" label="开票方：" placeholder="请输入开票方" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-field v-else label="开票方：" placeholder="请输入开票方" :value="appForm.invoiceSendName" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceSendId')"
      />
      <van-field v-if="appForm.invoiceReceiveType === 6" v-model="appForm.invoiceReceiveName" label="收票方：" placeholder="请输入收票方" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-field v-else label="收票方：" placeholder="请输入收票方" :value="appForm.invoiceReceiveName" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceReceiveId')"
      />
      <van-field v-model="appForm.invoiceTypeLabel" label="发票类型：" placeholder="请输入发票类型" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth" @click="fieldClick('invoiceTypes')"
      />
      <van-field v-model="appForm.productName" label="开票品名：" placeholder="请输入开票品名" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-field v-model="appForm.invoiceMeal" label="开票数量：" placeholder="请输入开票数量" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-field v-model="appForm.invoiceNum" label="开票张数：" placeholder="请输入开票张数" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-field v-model="appForm.invoiceMoney" label="开票金额(合计: 元)：" placeholder="请输入开票金额" required :rules="[{ required: true }]"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-field v-model="appForm.invoiceDate" label="开票时间：" placeholder="请输入开票时间" required :rules="[{ required: true }]"
                 :label-width="constants.labelWidth" :input-align="constants.input_align" @click="dialog.showDate = true"
      />
      <van-calendar v-model="dialog.showDate" :min-date="minDate" :max-date="maxDate" @confirm="confirmDate" />
      <van-field v-model="appForm.remark" label="备注：" placeholder="请输入备注"
                 :input-align="constants.input_align" :label-width="constants.labelWidth"
      />
      <van-cell title="发票原件：">
        <upload-file rectangle-style upload-text="发票原件" :query="query" :before-read="beforeUpload" @fileUploadSuccess="(response, file, fileList) => fileUploadSuccess('fileDetailInfoVos', response, file, fileList)" @change="(arr) => change('fileDetailInfoVos', arr)" />
      </van-cell>
      <van-form ref="auditForm">
        <div class="fixed-btn-bottom" style="z-index: 2">
          <van-row gutter="20">
            <van-col span="12">
              <van-button color="#1373CC" block type="primary" @click="handleSave">
                保存
              </van-button>
            </van-col>
            <van-col span="12">
              <van-button color="#1373CC" block type="primary" @click="$router.back()">
                返回
              </van-button>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </van-form>
    <!--下拉弹层-->
    <van-popup v-model="dialog.selectDialog" round position="bottom">
      <van-picker show-toolbar :columns="columns" :value-key="valueKey" @cancel="dialog.selectDialog = false"
                  @confirm="confirmPicker"
      />
    </van-popup>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Row, Col, Form, Field, Popup, Picker, Uploader, SwipeCell, Toast, Calendar, Checkbox, CheckboxGroup, RadioGroup, Radio } from 'vant'
import moment from 'moment'
import UploadFile from '@/components/upload-file'
export default {
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Calendar.name]: Calendar,
    [Toast.name]: Toast,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    [SwipeCell.name]: SwipeCell,
    UploadFile
  },
  data () {
    return {
      appForm: {
        orderId: '',
        orderNo: '',
        invoiceSendType: '',
        invoiceSendTypeLabel: '',
        invoiceNum: '',
        invoiceSendId: '',
        invoiceReceiveId: '',
        invoiceType: '',
        invoiceTypeLabel: '',
        productName: '',
        invoiceMeal: '',
        invoiceMoney: '',
        invoiceDate: '',
        remark: '',
        invoiceReceiveType: '',
        invoiceReceiveTypeLabel: '',
        fileDetailInfoVos: [],
        invoiceSendTyp: '',
        invoiceSendName: '',
        invoiceReceiveName: ''
      },
      files: [],
      columns: [],
      valueKey: '',
      listType: '',
      minDate: new Date(moment().subtract(10, 'year')),
      maxDate: new Date(moment().add(10, 'year')),
      dialog: {
        selectDialog: false, // 控制下拉弹层
        goodsDialog: false,
        showDate: false
      },
      query: {},
      platformOrgName: [],
      companyList: []
    }
  },
  created () {
    this.getCompanyDictToProject()
    if (this.$route.query.id) {
      this.getApplyDetail(this.$route.query.id)
    }
  },
  methods: {
    // 上传大小
    beforeUpload (file) {
      const isLt = file.size / 1024 / 1024 < 10
      if (!isLt) {
        Toast.fail('上传文件不能大于10M')
        return isLt
      } else {
        return isLt
      }
    },
    // 上传成功
    fileUploadSuccess (type, response, file, fileList) {
      this.appForm[type] = []
      if (!this._.isEmpty(fileList)) {
        fileList.forEach((item, index) => {
          if (item.response) {
            const files = item.response
            this.appForm[type].push({ fileName: files.fileName, fileUrl: files.fileUrl })
          } else if (item.fileId) {
            this.appForm[type].push({ fileName: item.fileName, fileUrl: item.fileUrl })
          }
        })
      } else {
        this.appForm[type] = []
      }
    },
    change (type, arr) {
      this.appForm[type] = arr
    },
    fieldClick (list) {
      this.listType = list
      this.dialog.selectDialog = true
      if (list === 'invoiceSendType' || list === 'invoiceReceiveType') {
        this.columns = this.constants.invoiceSendType
      } else if (list === 'invoiceTypes') {
        this.columns = this.constants.invoiceTypes
      } else if (list === 'invoiceSendId') {
        this.columns = this.platformOrgName
      } else if (list === 'invoiceReceiveId') {
        this.columns = this.companyList
      }
      const codeMapName = {
        invoiceSendType: 'label',
        invoiceReceiveType: 'label',
        invoiceTypes: 'label',
        invoiceSendId: 'orgName',
        invoiceReceiveId: 'orgName'
      }
      this.valueKey = codeMapName[list]
    },
    // 下拉弹层确定
    confirmPicker (value) {
      this.dialog.selectDialog = false
      if (this.listType === 'invoiceSendType') {
        this.appForm.invoiceSendType = value.value
        this.appForm.invoiceSendTypeLabel = value.label
      } else if (this.listType === 'invoiceReceiveType') {
        this.appForm.invoiceReceiveType = value.value
        this.appForm.invoiceReceiveTypeLabel = value.label
      } else if (this.listType === 'invoiceTypes') {
        this.appForm.invoiceType = value.value
        this.appForm.invoiceTypeLabel = value.label
      } else if (this.listType === 'invoiceSendId') {
        this.appForm.invoiceSendId = value.enterpriseId
        this.appForm.invoiceSendName = value.orgName
      } else if (this.listType === 'invoiceReceiveId') {
        this.appForm.invoiceReceiveId = value.enterpriseId
        this.appForm.invoiceReceiveName = value.orgName
      }
      this.showPicker = false
    },
    // 企业列表
    getCompanyDictToProject () {
      // 获取平台信息
      this.api.contract.invoice.otherCompanyList(0).then(result => {
        this.platformOrgName = result.data.value || []
      })
      // 获取企业客户
      this.api.contract.invoice.companyList(1).then(result => {
        this.companyList = result.data.value || []
      })
    },
    // 计划采购日期确认
    confirmDate (date) {
      this.dialog.showDate = false
      this.appForm.invoiceDate = moment(date).format('YYYY-MM-DD')
    },
    // 详情
    getApplyDetail () {
      this.api.contract.invoice.detail(this.$route.query.id).then(res => {
        this.appForm = res.data.value
      })
    },
    // 贸易申请保存
    handleSave () {
      this.$refs.appForm.validate().then(() => {
        const ajax = this.$route.query.id ? this.api.contract.invoice.updateInvoice(this.appForm) : this.api.contract.invoice.addApply(this.appForm)
        ajax.then(res => {
          Toast(res.data.message || '保存成功')
          this.$router.back()
        })
      })
    }
  }
}
</script>

<style lang="less">
.partition-form {
  margin-bottom:45px;
  .van-cell__title {
    color: #646566;
  }
}
</style>
